<template>
  <div class="adviceBox">
    <el-tabs style="height:100%" v-model="activeName1" class="demo-tabs" @tab-click="handleClick1">
      <el-tab-pane label="临时医嘱" name="3" style="height:calc(100% - 10px)">
        <temporary style="height:calc(100% - 10px)" :patientData="patientData" :searchListData="searchListData"
          @stepsUpdate="stepsUpdate" ref="temporaryRef"></temporary>
      </el-tab-pane>
      <el-tab-pane label="长期医嘱" name="1" style="height:calc(100% - 10px)">
        <yaoList style="height:calc(100% - 10px)" :patientData="patientData" :searchListData="searchListData"
          ref="yaoListRef"></yaoList>
      </el-tab-pane>
      <el-tab-pane label="医嘱" name="2" style="height:calc(100% - 10px)">
        <longIerm style="height:calc(100% - 10px)" :patientData="patientData" :searchListData="searchListData"
          ref="longIermRef" @addCallback="addTemporary">
        </longIerm>
      </el-tab-pane>
      
    </el-tabs>
    <BaseDialog :title="isRecord ? '下机' : '上机'" :isshow="visible" @handleShow="
        (val) => {
          visible = val
        }
      " width="560px">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="Rule.COMPUTRER" label-width="104px" label-position="right"
        class="demo-ruleForm col-333 lable-104 mar20">
        <el-form-item :label="isRecord ? '下机者：' : '上机者：'" prop="do_id">
          <el-select v-model="ruleForm.do_id" placeholder="请选择">
            <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="visible = false">
            取消
          </el-button>
          <el-button class="color-determine" type="primary" :loading="saveLoading" @click="saveLongItem">
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
  import { ref, nextTick, reactive, toRefs, onMounted, watch } from 'vue'
  import service from '@/utils/request'
  import longIerm from './longIerm.vue'
  import yaoList from './yaoList.vue'
  import temporary from './temporary.vue'
  import Rule from '@/utils/rule'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import BaseDialog from '@/components/Dialog/index.vue'
  export default {
    name: 'patentCase',
    components: {
      longIerm,
      temporary,
      BaseDialog,
      yaoList
    },
    props: ['patientData', 'searchListData'],
    emits: ['stepsUpdate'],
    setup(props, ctx) {
      const state = reactive({
        ruleForm: {},
        userList: [],
        visible: false,
        saveLoading: false,
        ruleFormRef: null,
        Rule: Rule,
        isRecord: false,
        activeName1: '3'
      })
      const temporaryRef = ref(null)
      const longIermRef = ref(null)
      const yaoListRef = ref(null)
      const addTemporary = () => {
        ElMessageBox.confirm('已加入临时医嘱，是否查看？', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            nextTick(() => {
              temporaryRef.value.getDataList()
              state.activeName1 = '3'
            })
          })
          .catch(() => { })

      }
      const handleClick1 = (e) => {
        state.activeName1 = e.props.name
        if (state.activeName1 == '1') {
            yaoListRef.value.getDataList()
          } else if (state.activeName1 == '2') {
            longIermRef.value.getDataList()
          } else {
            temporaryRef.value.getDataList()
          }
      }
      const getDataList = () => {
        nextTick(() => {
          if (state.activeName1 == '1') {
            yaoListRef.value.getDataList()
          } else if (state.activeName1 == '2') {
            longIermRef.value.getDataList()
          } else {
            temporaryRef.value.getDataList()
          }

        })
      }
      watch(
        () => props.patientData.patient_id,
        (val) => {
          if (val) {
            state.ruleForm.record_id = props.patientData.record_id
            state.ruleForm.patient_id = props.patientData.patient_id
          } else {
            state.ruleForm.record_id = 0
            state.ruleForm.patient_id = 0
          }
          getOnList()
        }
      )
      onMounted(() => {
        if (props.patientData.patient_id) {
          state.ruleForm.record_id = props.patientData.record_id
          state.ruleForm.patient_id = props.patientData.patient_id
        } else {
          state.ruleForm.record_id = 0
          state.ruleForm.patient_id = 0
        }
        state.activeName1 = '3'
        getUserList()
        getOnList()
      })
      const stepsUpdate = () => {
        ctx.emit('stepsUpdate')
      }
      const getOnList = async () => {
        if (!state.ruleForm.record_id) return
        let res = await service.post('/api/dialysis_record/is_on', {
          record_id: state.ruleForm.record_id,
        })
        if (res.code === 0) {
          state.isRecord = res.data.is_on
        }
      }
      const getUserList = async () => {
        let res = await service.post('/api/user/list')
        if (res.code === 0) {
          state.userList = res.data.records
        }
      }
      const saveLongItem = () => {
        state.ruleFormRef.validate(async (valid) => {
          if (valid) {
            let url = ''
            if (state.isRecord) {
              url = '/api/dialysis_record/off'
            } else {
              url = '/api/dialysis_record/on'
            }
            let res = await service.post(url, state.ruleForm)
            if (res.code === 0) {
              ElMessage.success(res.msg)
              state.visible = false
              ctx.emit('stepsUpdate')
              getOnList()
            }
          }
        })
      }

      const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
      const openComputer = () => {
        if (!state.ruleForm.patient_id) {
          ElMessageBox.alert('请先选择患者', '提示', {
            confirmButtonText: '确认',
          })
          return
        }
        if (!state.ruleForm.record_id) {
          ElMessageBox.alert(
            '当前患者的透析治疗记录还没保存，不能上机！',
            '提示',
            {
              confirmButtonText: '确认',
            }
          )
          return
        }
        state.ruleForm.do_id = userInfo.uid
        state.visible = true
      }

      return {
        ...toRefs(state),
        addTemporary,
        temporaryRef,
        longIermRef,
        yaoListRef,
        getDataList,
        openComputer,
        saveLongItem,
        stepsUpdate,
        handleClick1
      }
    },
  }
</script>

<style scoped lang="scss">
  .adviceBox {
    height: calc(100% - 80px);
  }

  ::v-deep .el-tabs__content {
    height: calc(100% - 60px);
  }

  ::v-deep.el-scrollbar__view {
    height: calc(100% - 60px);
  }

  .computer {
    position: fixed;
    bottom: 5%;
    right: 5%;
    cursor: pointer;
    z-index: 10;

    img {
      width: 64px;
      height: 64px;
    }
  }
</style>