<template>
  <div class="patentInfo" ref="tableBoxRef1">
    <el-scrollbar>
      <div class="cont">
        <div class="cont-main col999">
          <el-collapse v-model="activeCollNames" accordion>
            <el-collapse-item name="1" disabled>
              <template #title>
                <div class="updateBox flex-between">
                  <div class="type">
                    <div class="tit" @click="handleChange">
                      <span>医嘱</span>
                      <i class="fa fa-angle-right" :class="!activeCollNames ? 'bottom' : ''"></i>
                    </div>
                    <!-- <el-radio-group
                      v-model="searchData.use_flag"
                      @change="getDataList"
                      v-if="activeCollNames"
                    >
                      <el-radio-button label="1">在用</el-radio-button>
                      <el-radio-button label="0">停用</el-radio-button>
                    </el-radio-group>
                    <el-radio-group
                      v-model="searchData.drug_type"
                      @change="getDataList"
                      v-if="activeCollNames"
                    >
                      <el-radio-button label="0">全部</el-radio-button>
                      <el-radio-button label="2">院外医嘱</el-radio-button>
                      <el-radio-button label="1">透中医嘱</el-radio-button>
                    </el-radio-group> -->
                  </div>
                  <div class="upButtom">
                    <!-- <el-button
                      class="color-green"
                      type="primary"
                      @click="openWeight('batchLongIerm')"
                    >
                      <i class="fa fa-plus-circle"></i>批量新增
                    </el-button> -->
                  </div>
                </div>
              </template>
              <div class="tables">
                <el-table v-loading="dataListLoading" :data="tableData" size="small" border 
                  v-if="istable1">
                  <el-table-column show-overflow-tooltip prop="drug" label="用药" />
                  <el-table-column width="90" prop="use_type" label="用药方法" />
                  <!-- <el-table-column width="300" label="循环周期">
                    <template #default="scope">
                      <template v-if="scope.row.dict_cycle_loop">
                        <div
                          v-if="
                            scope.row.dict_cycle_loop.cycle === 'every_dialysis'
                          "
                        >
                          每次透析
                        </div>
                        <div
                          v-if="
                            scope.row.dict_cycle_loop.cycle === 'fixed_date'
                          "
                        >
                          每周[{{ cycleFiler(scope.row.dict_cycle_loop.day) }}]
                        </div>
                        <div
                          v-if="scope.row.dict_cycle_loop.cycle === 'frequency'"
                        >
                          每隔{{ scope.row.dict_cycle_loop.time }}次透析
                        </div>
                      </template>
                    </template>
                  </el-table-column> -->
                  <el-table-column width="100" prop="standard" label="规格" />
                  <el-table-column width="50" prop="dosage" label="剂量" />
                  <!-- <el-table-column width="90" label="院外用药">
                    <template #default="scope">
                      {{ scope.row.is_preopen === 1 ? '是' : '否' }}
                    </template>
                  </el-table-column> -->
                  <el-table-column width="80" prop="dosage_unit" label="剂量单位" />
                  <el-table-column width="60" prop="frequency" label="频度" />
                  <el-table-column width="60" prop="num" label="用量" />
                  <el-table-column width="80" prop="doctor_name" label="医生名" />
                  <el-table-column show-overflow-tooltip prop="remark" label="备注" />
                  <el-table-column width="60" prop="frequency" label="频度" />
                  <el-table-column width="150" prop="start_time" label="开嘱时间" />
                  <el-table-column width="150" prop="end_time" label="结束时间" />
                  <!-- <el-table-column prop="address" label="医嘱套" /> -->
                  <el-table-column fixed="right" label="操作" width="70">
                    <template #default="scope">
                      <!-- <el-button
                        type="text"
                        size="small"
                        v-if="searchData.use_flag === '1'"
                        @click="deatilsClick(scope.row)"
                      >
                        <i class="txIons stop"></i>
                      </el-button>
                      <el-button
                        type="text"
                        size="small"
                        v-if="searchData.use_flag === '1'"
                        @click="modParient(scope.row)"
                      >
                        <i class="txIons modify"></i>
                      </el-button>
                      <el-button
                        type="text"
                        size="small"
                        @click="delParient(scope.row)"
                      >
                        <i class="txIons del"></i>
                      </el-button> -->
                      <el-button type="text" size="small" v-if="searchData.use_flag === '1' "
                        @click="addTemporary(scope.row)">
                        <i class="txIons lower" v-if="scope.row.isClick=='1'"></i>
                        <i class="txIons lower2" v-if="scope.row.isClick=='2'"></i>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- <div class="pagination-box">
                <el-pagination
                  v-model:currentPage="pageData.currentPage"
                  v-model:page-size="pageData.pageSize"
                  :page-sizes="[10, 20, 50, 100]"
                  :background="true"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalSum"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div> -->
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </el-scrollbar>
    <BaseDialog title="停用" :isshow="visibleItem" @handleShow="handleShow" width="600px" top="20%">
      <div class="tongluBox">
        <el-form :model="stupData" label-width="110px" label-position="right" class="demo-ruleForm col-333">
          <el-form-item label="停用原因:">
            <el-input v-model="stupData.group_name" placeholder="请输入停用原因" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="visibleItem = false">
            取消
          </el-button>
          <el-button @click="accessItem" :loading="saveLoading" class="color-determine" type="primary">
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <batchLongIerm v-if="isBatchLongIerm" ref="batchLongIermRef" :patientId="patientId" @refreshDataList="getDataList">
    </batchLongIerm>
    <addLongIerm v-if="isAddLongIerm" ref="addLongIermRef" :patientId="patientId" @refreshDataList="getDataList">
    </addLongIerm>
  </div>
</template>
<script>
  import { reactive, toRefs, onMounted, nextTick, watch } from 'vue'
  import { getConstHei } from '@/utils/tool.js'
  import service from '@/utils/request'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import batchLongIerm from '@/views/patient/Details/advice/batchLongIerm.vue'
  import addLongIerm from '@/views/patient/Details/advice/addLongIerm.vue'
  import BaseDialog from '@/components/Dialog/index.vue'
  export default {
    name: 'programme',
    components: {
      batchLongIerm,
      addLongIerm,
      BaseDialog,
    },
    props: ['patientData', 'searchListData'],
    emits: ['addCallback'],
    setup(props, ctx) {
      const state = reactive({
        ruleForm: {},
        infoHei: '',
        searchData: {
          is_preopen: '0',
          use_flag: '1',
          drug_type: '0',
          scene: 'report',
        },
        tableData: [],
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        dataListLoading: false,
        isBatchLongIerm: false,
        batchLongIermRef: null,
        isAddLongIerm: false,
        addLongIermRef: null,
        stupData: {},
        visibleItem: false,
        saveLoading: false,
        patientId: 0,
        record_id: 0,
        activeCollNames: '1',
        istable1: false,
        tableHei1: 0,
        tableBoxRef1: null
      })

      watch(
        () => props.patientData.patient_id,
        (val) => {
          if (val) {
            state.patientId = val
            state.record_id = props.patientData.record_id
          } else {
            state.patientId = 0
            state.record_id = 0
          }
          getDataList()
        }
      )

      onMounted(() => {
        state.istable1 = false
        state.tableHei1 = localStorage.getItem('tableHeight') || 300
        console.log(state.tableHei1)
        setTimeout(() => {
          state.istable1 = true
        }, 100);
        state.infoHei = getConstHei()
        if (props.patientData.patient_id) {
          state.patientId = props.patientData.patient_id
          state.record_id = props.patientData.record_id
        } else {
          state.patientId = 0
          state.record_id = 0
        }
        getDataList()
      })
      const getDataList = async () => {
        state.searchData.patient_id = state.patientId
        state.searchData.record_id = state.record_id
        state.dataListLoading = true
        let res = await service.post(
          '/api/patient/get_his_patient_advice_list',
          state.searchData
        )
        state.dataListLoading = false
        if (res.code === 0) {
          state.tableData = res.data.list
          state.tableData.forEach(item => {
            item.isClick = '1'
          })
          
          // state.pageData.totalSum = res.data.total
        }
      }

      const handleSizeChange = (val) => {
        state.pageData.pageSize = val
        getDataList()
      }

      const handleCurrentChange = (val) => {
        state.pageData.currentPage = val
        getDataList()
      }

      const openWeight = (type, val) => {
        if (!state.patientId || state.patientId === 0) {
          ElMessage({
            message: '请先选择患者',
            type: 'warning',
          })
          return
        }
        switch (type) {
          case 'batchLongIerm':
            state.isBatchLongIerm = true
            nextTick(() => {
              state.batchLongIermRef.initData()
            })
            break
          case 'addLongIerm':
            state.isAddLongIerm = true
            nextTick(() => {
              state.addLongIermRef.initData('', val)
            })
            break
        }
      }

      const cycleFiler = (item) => {
        if (!item) return
        let row = []
        item.forEach((el) => {
          if (el === 'monday') {
            row.push('周一')
          }
          if (el === 'tuesday') {
            row.push('周二')
          }
          if (el === 'wednesday') {
            row.push('周三')
          }
          if (el === 'thursday') {
            row.push('周四')
          }
          if (el === 'friday') {
            row.push('周五')
          }
          if (el === 'saturday') {
            row.push('周六')
          }
          if (el === 'sunday') {
            row.push('周日')
          }
        })
        return row.sort().join(',')
      }

      const handleShow = (val) => {
        state.visibleItem = val
      }

      const deatilsClick = (item) => {
        state.stupData.id = item.id
        state.stupData.group_name = ''
        state.visibleItem = true
      }
      const accessItem = async () => {
        state.saveLoading = true
        let res = await service.post('/api/advice/stop', state.stupData)
        state.saveLoading = false
        if (res.code === 0) {
          ElMessage.success(res.msg)
          state.visibleItem = false
          getDataList()
        }
      }
      const modParient = (item) => {
        state.isAddLongIerm = true
        nextTick(() => {
          state.addLongIermRef.initData(item, '0')
        })
      }
      const delParient = (item) => {
        ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await service.post('/api/advice/delete', {
              id: item.id,
            })
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
            }
          })
          .catch(() => { })
      }
      // 添加到临时医嘱
      const addTemporary = async (item) => {
        item.isClick = '2'
        let param = JSON.parse(JSON.stringify(item))
        param.patient_id = state.patientId
        param.record_id = state.record_id
        param.doctor_id = JSON.parse(localStorage.getItem('ms_userData')).id
        param.doctor_name = JSON.parse(localStorage.getItem('ms_userData')).name
        let res = await service.post('/api/patient/add_his_patient_advice', param)
        if (res.code === 0) {
          ElMessage.success(res.msg)
          ctx.emit('addCallback')
        }
      }

      const handleChange = () => {
        if (state.activeCollNames) {
          state.activeCollNames = ''
        } else {
          state.activeCollNames = '1'
        }
      }

      return {
        ...toRefs(state),
        openWeight,
        getDataList,
        accessItem,
        deatilsClick,
        modParient,
        delParient,
        handleShow,
        handleSizeChange,
        handleCurrentChange,
        cycleFiler,
        handleChange,
        addTemporary,
      }
    },
  }
</script>

<style scoped lang="scss">
  .patentInfo {
    margin-top: 8px;
  }

  .podBot {
    padding-bottom: 30px;
  }

  .updateBox {
    height: 36px;
    width: 100%;
    align-items: center;

    .el-radio-group {
      margin-right: 30px;
    }

    .type {
      display: flex;
      align-items: center;

      .tit {
        display: inline-block;
        font-size: 18px;
        color: #000000;
        line-height: 32px;
        margin-right: 20px;
        cursor: pointer;

        .fa {
          font-size: 24px;
          margin-left: 10px;
          color: #999999;
          transition: transform 0.3s;

          &.bottom {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .cont-main {
    &:deep(.el-collapse-item__arrow) {
      width: 0;
    }

    &:deep(.el-collapse-item__header) {
      border-bottom: 0;
      cursor: default;
    }

    &:deep(.el-collapse-item__wrap) {
      border-bottom: 0;
    }
  }

  .tables {
    margin-top: 20px;
  }

  .parient-tag {
    .el-tag {
      margin-right: 10px;
    }
  }

  .tongluBox {
    margin-top: 20px;
  }
</style>